<template>
  <div class="user-data py-106 pb-42 text-center">
    <template v-if="!isDc">
      <h1 data-test="user-data-title" class="text-42 text-grey-900 leading-normal pb-16">
        {{ title }}
      </h1>
      <p class="text-20 text-grey-500 px-71 pb-71">
        {{ text }}
      </p>
    </template>
    <user-data-items :cards="children" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserData',

  components: {
    UserDataItems: () => import('@/components/UserDataItems.vue'),
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    children: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters('auth', ['isDc']),
  },
}
</script>
